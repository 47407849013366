.about {
  color: white;
  font-family: 'Cinzel', serif;
  width: 80%;
  font-size: 2em;
  margin-top: 3%;
}

.bar {
  width: 35px;
  height: 5px;
  background-color: black;
  margin: 6px 0;
}

.contact_icon {
  width: auto;
  border: none;
}

.media h4 {
  margin-left: auto;
  margin-right: auto;
  line-height: 1em;
}

.media a {
  font-size: 1em;
}

hr {
  opacity: 0.5;
}