@import url('https://fonts.googleapis.com/css?family=Cinzel&display=swap');

body {
  font-family: 'Cinzel', serif;
  background-color: #72767f;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.content {
  margin-top: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 500px;
}

.index {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h3 {
  width: 75%;
  text-align: center;
  color: white;
  font-size: 2.2em; 
  line-height: .5;
  font-family: 'Cinzel', serif;
  margin-top: 50px;
}

h1 {
  line-height: 0;
}

@media screen and (max-width: 981px) {
  #name {
    line-height: 1em;
    width: 40%;
  }
  #divide {
    display: none;
  }
}

@media screen and (max-width: 545px) {
  #name {
    width: 70%;
  }
}